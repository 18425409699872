<template>
  <b-row align-h="center">
    <b-form class="box-form-login mt-5">
      <h1 class="title-login text-center pt-2 pb-4">
        <b>Credenciais de Acesso</b>
      </h1>

      <b-alert show variant="danger" v-if="erroMensagem">{{erroMensagem}}</b-alert>

      <b-input-group class="mb-2">
        <b-input-group-prepend is-text>
          <b-icon icon="person-fill" variant="grey" />
        </b-input-group-prepend>
        <b-form-input id="cpf" v-model="cpf" v-mask="'###.###.###-##'" type="text" required placeholder="CPF" size="lg" autofocus aria-describedby="CPF" />
      </b-input-group>

      <b-input-group class="mb-2" v-if="oficios">
        <b-form-select v-model="oficioSelecionado">
          <option v-for="f in oficios" :value="f" :key="f.oficioId">{{f.oficioNome}}</option>
        </b-form-select>
      </b-input-group>

      <b-input-group class="mb-2">
        <b-input-group-prepend is-text>
          <b-icon icon="lock-fill" variant="grey" />
        </b-input-group-prepend>
        <b-form-input id="senha" v-model="password" type="password" required placeholder="Senha" size="lg" aria-describedby="senha" />
      </b-input-group>

      <b-input-group class="mb-2" v-if="this.mfaAtivado">
        <b-input-group-prepend is-text>
          <b-icon icon="shield-lock-fill" variant="grey" />
        </b-input-group-prepend>
        <b-form-input id="codigo" v-model="codigo" v-mask="'######'" type="text" required placeholder="Código de Google Auth" size="lg" aria-describedby="codigo" />
      </b-input-group>

      <p class="text-center py-2">
        <nuxt-link to="/password-reset" class="btn btn-link">Recuperar Senha</nuxt-link>
      </p>

      <b-button type="submit" @click.prevent="login" class="blue-light" block size="lg">
        <span v-if="!this.loading" class="d-block py-2">ENTRAR</span>
        <b-spinner v-if="this.loading" class="my-1" />
      </b-button>
    </b-form>

    <div class="d-block w-100 text-center py-3 text-grey text-darken-2">
      Contato: suporte@sistemaregina.com.br WhatsApp(41)3798-5424
    </div>
  </b-row>
</template>

<script>
import { default as authService } from "~/services/auth";
import { FormRules, cleanCpf } from "~/services/utils";

export default {
  name: "Login",
  layout: "unsigned",
  mixins: [FormRules],
  data() {
    return {
      cpf: "",
      password: "",
      codigo: "",
      loading: false,
      mfaAtivado: false,
      erroMensagem: null,
      usuarioId: null,
      oficios: null,
      oficioSelecionado: null
    };
  },
  watch: {
    oficioSelecionado: {
      handler: function (value) {
        if (value) {
          this.mfaAtivado = value.mfaAtivado
          this.usuarioId = value.usuarioId
          this.password = ""
        }
      }
    },
    cpf: {
      handler: function (value) {
        if (value && value.length == 14) {
          this.mfaAtivado = false
          this.$axios
            .$get(`${process.env.API_HOST}/auth/checkCpf/${cleanCpf(value)}`)
            .then(response => {
              this.oficios = null
              if (response == null || response.length == 0) {
                alert("Erro, CPF não cadastrado!")
                return
              }

              // Somente 1 Oficio
              if (response.length === 1) {
                this.usuarioId = response[0].usuarioId
                if (response[0].mfaAtivado === true) {
                  this.mfaAtivado = true
                }
              }
              else
                this.oficios = response
            })
            .catch(error => {
              console.log("e", error)
              alert("Erro, CPF não cadastrado (2)!")
            })

        }
      },
      deep: true
    }
  },
  methods: {
    redirectMain() {
      this.$store.dispatch("fetchMe").then(() => {
        this.$router.push("/");
      });
    },
    login() {

      this.erroMensagem = null

      if (this.oficios && !this.oficioSelecionado) {
        alert('Selecione o Oficio')
        return
      }

      if (this.mfaAtivado === true && !this.codigo) {
        alert('Digite o código de validação do Google Authenticator')
        return
      }

      this.loading = true;
      let data = {
        cpf: cleanCpf(this.cpf),
        senha: this.password,
        usuarioId: this.usuarioId
      };

      if (this.mfaAtivado === true)
        data.codigo = this.codigo

      this.$axios
        .$post(`${process.env.API_HOST}/auth/login`, data)
        .then(response => {
          authService.setToken(response.data.token);

          if (response.data.diasVctoCertificado && response.data.diasVctoCertificado <= 20) {
            this.$bvModal.msgBoxOk(`Seu Certificado Digital irá expirar em ${response.data.diasVctoCertificado} dias, regularize para evitar a suspensão de emissão de Selos Digitais`, {
              title: 'Aviso!!',
              headerClass: 'border-bottom-0',
              footerClass: 'border-top-0',
            }).then(value => {
              this.redirectMain();
            })
              .catch(err => {
                // An error occurred
              })
            return
          }

          this.redirectMain();
        })
        .catch(error => {
          this.erroMensagem = error.response.data ? error.response.data.errors[0] : "Usuário e/ou senha inválido(s)!"
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="stylus" scoped>
.title-login {
  font-size: 1.2rem;
  color: #172B4D;
}

.bt-info {
  border-radius: 4px;
  font-size: 1rem;
  margin-left: 5px;
}

.bt-info:hover {
  _color: #172B4D;
}
</style>
